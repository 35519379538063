<template>
  <div
    class="timeline-entry"
    :class="timelineEntryDesign + ' ' + ( wide ? 'wide' : '' )"
    @click="goToBooking"
  >
    <m-travel-icon
      class="icon"
      :type="type"
      color="white"
      :bg-color="getTravelContentHex(type)"
      size="32px"
      circle
    />
    <div class="startTime strong">
      {{ startTime | toTime }}
    </div>
    <div class="startSubtitle">
      {{ startSubtitle }}
    </div>

    <div class="grey-box">
      <slot name="body" v-bind="{ greyBoxInfo }">
        <div class="type">
          {{ $tc(`content_type.${type}`) }}
        </div>
        <div v-if="greyBoxInfo" class="grey-box-info">
          {{ greyBoxInfo }}
        </div>
        <div v-if="booking && booking.price" class="price">
          {{ booking.price.total.text }}
        </div>
        <div class="duration">
          {{ toDuration(startTime, endTime) }}
        </div>
      </slot>
    </div>

    <div class="endTime strong">
      {{ endTime | toTime }}
    </div>
    <div class="endSubtitle">
      {{ endSubtitle }}
    </div>
    <div class="timeline" :style="`--timeline-color: ${color || '#4361EE'}`">
      <template v-if="timelineEntryDesign === 'single'">
        <div class="dot" />
        <div class="line" />
        <div class="dot" />
      </template>
      <template v-else-if="timelineEntryDesign === 'start'">
        <div class="dot" />
        <div class="line" />
        <div class="line" />
      </template>
      <template v-else-if="timelineEntryDesign === 'end'">
        <div class="line" />
        <div class="line" />
        <div class="dot" />
      </template>
      <template v-else>
        <div class="line" />
        <div class="line" />
        <div class="line" />
      </template>
    </div>
  </div>
</template>

<script>
import { MTravelIcon } from 'components'
import authentication from 'mixins/authentication'
import date from 'utils/date-time'
import travelContents from 'mixins/travelContents'
const { toMediumDate, toCivilTime, getDateDiff, minutesToHours, toDayOfMonth } = date

export default {
  name: 'MBookingTimelineEntry',
  components: {
    MTravelIcon
  },
  filters: {
    toDate (val) {
      return toMediumDate(val)
    },
    toTime (val) {
      return toCivilTime(val)
    }
  },
  mixins: [authentication, travelContents],
  props: {
    timelineEntryDesign: {
      type: String,
      default: 'single'
    },
    wide: Boolean,
    type: String,
    title: String,
    startSubtitle: String,
    startTime: String,
    endSubtitle: String,
    endTime: String,
    useCardDesign: Boolean,
    color: String,
    booking: {
      type: Object,
      default: null
    }
  },
  computed: {
    greyBoxInfo () {
      if (!this.booking) return null
      const { attributes, type } = this.booking
      switch (type) {
      default:
        return null
      case 'flight':
        return attributes.outbound_legs[0].flight_number
      case 'carclub':
        return attributes.vehicle
      }
    }
  },
  methods: {
    toDayOfMonth,
    goToBooking () {
      if (!this.booking) return
      if (this.$route.name === 'bookings-show') return
      const [path] = this.booking.reference.split('-')
      if (this.hasPermission('can.book.for.anyone')) {
        this.$router.push({ hash: `/booking`, query: { booking: path } })
      } else {
        this.$router.push(`/bookings/${path}`)
      }
    },
    toDuration (start, end) {
      if (!end) return ''
      if (this.type === 'hotel') {
        const days = getDateDiff(date.toCivilDate(end), date.toCivilDate(start), 'days').days
        return this.$tc('num.nights', days)
      }
      const val = getDateDiff(end, start, 'minutes')
      return minutesToHours(val.minutes)
    }
  }
}
</script>

<style lang="stylus" scoped>
.timeline-entry
  line-height 1.3em
  font-size 90%
  padding 0 30px
  display grid
  grid-template-columns 20px 30px 70px auto 30px 30px
  grid-template-rows repeat(5, 1fr)
  grid-row-gap 15px
  grid-template-areas \
  "timeline ... startTime startSubtitle startSubtitle none" \
  "timeline icon grey-box grey-box grey-box grey-box" \
  "timeline icon grey-box grey-box grey-box grey-box" \
  "timeline icon grey-box grey-box grey-box grey-box" \
  "timeline ... endTime endSubtitle endSubtitle ..."
  color black

  &.start
    padding-bottom 0
    .line
      height calc(100% + 10px)

  &.middle
    .line
      height calc(100% + 10px)

  &.end
    padding-top 0
    .line
      height calc(100% + 10px)

  &.wide
    padding-left 0
    padding-right 0

.strong
    font-weight 550

.icon
  grid-area icon
  align-self center
.startTime
  grid-area startTime
  align-self start
.endTime
  grid-area endTime
  align-self end
.startSubtitle
  grid-area startSubtitle
  text-overflow ellipsis
  white-space nowrap
  overflow hidden
  align-self start
.endSubtitle
  grid-area endSubtitle
  text-overflow ellipsis
  white-space nowrap
  overflow hidden
  align-self end
.price
  grid-area price
  justify-self flex-end
  font-weight 550
.timeline
  margin 5px 0
  grid-area timeline
  display grid
  grid-template-rows 12px auto 12px
  align-self stretch
  align-items center
  justify-items center
  .dot
    background var(--timeline-color)
    border-radius 50%
    width 13px
    height 13px
  .line
    background var(--timeline-color)
    height 100%
    width 2px
.type
  grid-area type
  color var(--q-color-primary)
  font-weight 800
.duration
  grid-area duration
  color var(--q-color-primary)
  font-weight 450
  align-self end
.grey-box-info
  grid-area grey-box-info
  font-size 90%
  margin 2px 0

.grey-box
  background rgba(100, 100, 100, 0.05)
  grid-area grey-box
  border-radius 15px
  margin 0 20px 0 15px
  padding 7px 12px
  display grid
  grid-template-columns 1fr 1fr
  grid-template-areas "type price" "grey-box-info grey-box-info" "duration duration" "dropdown dropdown"
  .grey-box-slot
    grid-area dropdown
</style>
