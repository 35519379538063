<template>
  <div>
    <div v-for="(entry, index) in entries" :key="index">
      <h6 v-if="entry.day" style="padding-left: 15px">
        {{ toMediumDate(entry.day) }}
      </h6>
      <m-booking-timeline-entry v-for="booking in entry.bookings" :key="booking.reference" v-bind="mapBooking(booking)" />
    </div>
  </div>
</template>

<script>
import date from 'utils/date-time'
import MBookingTimelineEntry from './Entry/index.vue'
import travelContents from 'mixins/travelContents'
const { toCivilDate, toMediumDate } = date

export default {
  name: 'MBookingTimeline',
  components: {
    MBookingTimelineEntry
  },
  mixins: [travelContents],
  props: {
    entries: Array
  },
  methods: {
    toMediumDate,
    toCivilDate,
    getBookingStartLocation (booking) {
      const { attributes, lookups, type } = booking
      switch (type) {
      case 'planner':
        return booking.origin
      case 'ridehailing':
        return attributes?.start_location
      case 'rail':
        return lookups[attributes?.outbound_legs?.[0]?.depart_atoc] + ' (' + attributes?.outbound_legs?.[0]?.depart_atoc + ')'
      case 'flight':
        return lookups[attributes?.outbound_legs?.[0]?.depart_iata].name + ' (' + attributes?.outbound_legs?.[0]?.depart_iata + ')'
      case 'bikehire':
        return attributes.station_name
      case 'drt':
        return attributes.pickup_location
      case 'ferry':
        return attributes.outbound_legs[0].depart_port
      case 'bus':
        return attributes?.origin
      default:
        return undefined
      }
    },
    getBookingEndLocation (booking) {
      const { attributes, lookups, type } = booking
      switch (type) {
      case 'planner':
        return booking.destination
      case 'ridehailing':
        return attributes?.end_location
      case 'rail':
        return lookups[attributes?.outbound_legs?.[attributes.outbound_legs.length - 1]?.arrive_atoc] + ' (' + attributes?.outbound_legs?.[attributes.outbound_legs.length - 1]?.arrive_atoc + ')'
      case 'flight':
        return lookups[attributes?.outbound_legs?.[attributes.outbound_legs.length - 1]?.arrive_iata].name + ' (' + attributes?.outbound_legs?.[attributes.outbound_legs.length - 1]?.arrive_iata + ')'
      case 'drt':
        return attributes.dropoff_location
      case 'ferry':
        return attributes.outbound_legs[0].arrive_port
      case 'bus':
        return attributes.destination
      default:
        return undefined
      }
    },
    getBookingStartTime (booking) {
      const { attributes, type } = booking
      switch (type) {
      case 'planner':
        return booking.startTime
      case 'rental':
      case 'ridehailing':
      case 'carclub':
        return attributes?.depart_at
      case 'hotel':
        return attributes?.check_in
      case 'rail':
      case 'flight':
      case 'ferry':
        return attributes?.outbound_legs?.[0]?.depart_at
      case 'bikehire':
      case 'drt':
        return attributes?.pickup_time
      case 'bus':
        return attributes?.departing?.split(' ')[0] + ' ' + attributes?.departing?.split(' ')[1]
      default:
        return undefined
      }
    },
    getBookingEndTime (booking) {
      const { attributes, type } = booking
      switch (type) {
      case 'planner':
        return booking.endTime
      case 'rental':
      case 'carclub':
        return attributes?.arrive_at
      case 'hotel':
        return attributes?.check_out
      case 'rail':
      case 'flight':
        return attributes?.outbound_legs?.[attributes.outbound_legs.length - 1]?.arrive_at
      case 'ferry':
        return attributes?.outbound_legs?.[0]?.arrive_at
      case 'bus':
        return attributes?.arriving?.split(' ')[0] + ' ' + attributes?.arriving?.split(' ')[1]
      default:
        return undefined
      }
    },
    mapBooking (booking) {
      const { reference, type } = booking

      const mode = type === 'planner' ? booking.mode : type

      const startTime = (this.getBookingStartTime(booking))
      const endTime = (this.getBookingEndTime(booking))

      const startSubtitle = this.getBookingStartLocation(booking)
      const endSubtitle = this.getBookingEndLocation(booking)

      const color = this.getTravelContentHex(mode) || '#4361EE'

      return {
        type: mode,
        startTime,
        endTime,
        startSubtitle,
        endSubtitle,
        color,
        booking,
        reference
      }
    }
  }
}
</script>
